
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";

import CSATBarChart from "@/components/CSATBarChart.vue";

@Component({
  components: {
    ItemPreview,
    CSATBarChart,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
  },
})
export default class GraphsView extends Vue {
  public loading: boolean = false;
  public columns: any[] = [];
  public rows: any[] = [];
  public connectedModels: any = [];
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public dialog: boolean = true;
  public e6: number = 0;
  public loadingButton: boolean = false;
  public setupType: any = "csat";
  public recommendation: number = -1;
  public recommendationCsat: number = -1;
  public recommendationDate: number = -1;
  public recommendationResponse: number = -1;
  public recommendationResolve: number = -1;
  public csatDialog: boolean = false;
  public dateDialog: boolean = false;
  public metaDataError: any = null;
  public redirect: any = "graphs";

  public rowsToShow: any[] = [];
  public uploadError: any = null;
  public loadingProgress: number | null = null;

  public headers = [
    {
      align: "left",
      sortable: false,
    },
    {
      text: "Model name",
      sortable: true,
      value: "model_id",
      align: "left",
    },
    {
      text: "Model id",
      sortable: true,
      value: "model_id",
      align: "left",
    },
    {
      text: "Latest model version?",
      sortable: true,
      value: "checkpoint_name",
      align: "left",
    },
    {
      text: "Created at",
      sortable: true,
      value: "created_at",
      align: "left",
    },
    {
      text: "Status",
      sortable: true,
      value: "status",
      align: "left",
    },
  ];

  public redirector() {
    if (this.redirect === "graphs") {
      this.$router
        .push(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs`,
        )
        .catch(() => {});
    } else if (this.redirect === "csat") {
      this.$router
        .push(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${
            this.$router.currentRoute.params.id
          }/dashboard/graphs/model/${parseInt(this.$router.currentRoute.params.modelid, 10)}/csat`,
        )
        .catch(() => {});
    } else {
      this.$router
        .push(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${
            this.$router.currentRoute.params.id
          }/dashboard/graphs//model/${parseInt(this.$router.currentRoute.params.modelid, 10)}/time`,
        )
        .catch(() => {});
    }
  }

  public async goBack() {
    if (this.setupType === "csat") {
      if (this.e6 === 2) {
        console.log("back");
        this.e6 = 1;
        this.recommendationDate = -1;
      } else if (this.e6 === 3) {
        this.recommendationCsat = -1;
        this.e6 = 2;
        this.metaDataError = null;
      }
    } else {
      if (this.e6 === 2) {
        console.log("back");
        this.e6 = 1;
        this.recommendationDate = -1;
      } else if (this.e6 === 3) {
        console.log("back");
        this.e6 = 2;
        this.recommendationResponse = -1;
      } else if (this.e6 === 4) {
        console.log("back");
        this.e6 = 3;
        this.recommendationResolve = -1;
        this.metaDataError = null;
      }
    }
  }

  public checkCsat() {
    try {
      if (this.recommendationCsat === -1) {
        return true;
      } else {
        const checker: any = [];
        this.previewRows.forEach((obj) => {
          checker.push(obj["column_" + this.recommendationCsat.toString()]);
        });
        console.log(checker);
        const allIntegers: boolean = checker.every((strNum: string) => {
          const num: number = parseInt(strNum, 10);
          console.log(Number.isInteger(num));
          return Number.isInteger(num) && num > 0 && num < 100; // <100 to exclude dates
        });
        return allIntegers;
      }
    } catch {
      console.log("error while attemting to check if date");
      return true;
    }
  }

  public areAllStringsDates(arr: string[]): boolean {
    console.log(arr);
    const dateRegex = /^(\d{4}-\d{2}-\d{2}|\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2})$/;
    return arr.every((str) => dateRegex.test(str));
  }

  public checkDate() {
    try {
      if (this.recommendationDate === -1) {
        return true;
      } else {
        const checker: any = [];
        this.previewRows.forEach((obj) => {
          checker.push(obj["column_" + this.recommendationDate.toString()]);
        });
        return this.areAllStringsDates(checker);
      }
    } catch {
      console.log("error while attemting to check if date");
      return true;
    }
  }

  public nextStep() {
    if (this.setupType === "csat") {
      if (this.e6 === 2 && !this.checkDate()) {
        console.log(this.checkDate());
        this.e6++;
      } else if (this.e6 === 3 && !this.checkCsat()) {
        console.log(this.checkCsat());
        this.csatDialog = true;
      } else {
        // trigger setup
        if (this.e6 === 3) {
          this.setMetaData("csat");
        } else {
          this.e6++;
          console.log(this.recommendationDate, this.recommendationCsat);
        }
      }
    } else {
      if (this.e6 === 2 && !this.checkDate()) {
        console.log(this.checkDate());
        this.e6++;
      } else {
        // trigger setup
        if (this.e6 === 4) {
          this.setMetaData("time");
        } else {
          this.e6++;
          console.log(this.recommendationDate, this.recommendationCsat);
        }
      }
    }
  }

  public async setMetaData(type: string) {
    this.metaDataError = null;
    this.loadingButton = true;
    let metaData: any;
    if (type === "time") {
      metaData = {
        resolve_time: this.recommendationResolve,
        response_time: this.recommendationResponse,
        date: this.recommendationDate,
      };
    } else {
      metaData = {
        csat: this.recommendationCsat,
        date: this.recommendationDate,
      };
    }
    console.log(metaData);
    await api
      .setMetaData(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        metaData,
      )
      .then((r) => {
        this.loadingButton = false;
        this.e6 += 1;
      })
      .catch((error) => {
        console.log("error when setting metadata", error);
        this.metaDataError = error.response;
        this.loadingButton = false;
      });
  }

  get activeRecommendation() {
    return this.columns[this.recommendation].value;
  }

  get activeRecommendationCsat() {
    if (this.recommendationCsat > -1) {
      return this.columns[this.recommendationCsat].value;
    } else {
      return "None";
    }
  }

  get activeRecommendationDate() {
    if (this.recommendationDate > -1) {
      return this.columns[this.recommendationDate].value;
    } else {
      return "None";
    }
  }

  get activeRecommendationResponse() {
    if (this.recommendationResponse > -1) {
      return this.columns[this.recommendationResponse].value;
    } else {
      return "None";
    }
  }

  get activeRecommendationResolve() {
    if (this.recommendationResolve > -1) {
      return this.columns[this.recommendationResolve].value;
    } else {
      return "None";
    }
  }

  public highlight(key, value) {
    let newColumn = Number(key);

    // Check if newColumn is NaN or undefined, and if so, set it to -1
    newColumn = isNaN(newColumn) || typeof newColumn === "undefined" ? -1 : newColumn;
    console.log(typeof Number(key));
    console.log(newColumn);
    console.log("running highlight", "key", key, "val", value);
    if (this.setupType === "csat") {
      if (this.e6 === 2) {
        // TEXT
        this.recommendationDate = newColumn;
      } else if (this.e6 === 3) {
        // LABEL

        if (Number(key) !== this.recommendationDate) {
          if (Number(key) === this.recommendationCsat) {
            this.recommendationCsat = -1;
          } else {
            this.recommendationCsat = newColumn;
          }
        }
      }
    } else {
      if (this.e6 === 2) {
        // TEXT
        this.recommendationDate = newColumn;
      } else if (this.e6 === 3) {
        if (Number(key) !== this.recommendationDate) {
          if (Number(key) === this.recommendationResponse) {
            this.recommendationResponse = -1;
          } else {
            this.recommendationResponse = newColumn;
          }
        }
      } else if (this.e6 === 4) {
        if (
          Number(key) !== this.recommendationDate &&
          Number(key) !== this.recommendationResponse
        ) {
          if (Number(key) === this.recommendationResolve) {
            this.recommendationResolve = -1;
          } else {
            this.recommendationResolve = newColumn;
          }
        }
      }
    }
    // } else if (this.e6 == 4) {
    //   if (Number(key) != this.recommendation && Number(key) != this.recommendationLabels) {
    //     if (Number(key) === this.recommendationCsat) {
    //       this.recommendationCsat = -1;
    //     } else {
    //       this.recommendationCsat = Number(key);
    //       console.log("HERE", this.checkCsat());
    //     }
    //   }
    // } else if (this.e6 == 5) {
    //   if (
    //     Number(key) != this.recommendation &&
    //     Number(key) != this.recommendationLabels &&
    //     Number(key) != this.recommendationCsat
    //   ) {
    //     if (Number(key) === this.recommendationDate) {
    //       this.recommendationDate = -1;
    //     } else {
    //       this.recommendationDate = Number(key);
    //       console.log("Here", this.checkDate());
    //     }
    //   }
    // }
  }

  public async chooseDataset() {
    this.loadingButton = true;
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loadingButton = false;
        this.recommendation = r.data.recommendation;
        this.uploadError = null;
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
        this.loadingButton = false;
      });
  }

  get getRecommendation() {
    if (typeof this.recommendation !== "undefined") {
      return this.recommendation;
    } else {
      return 2;
    }
  }

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  // functions for table
  public parsedDate(timestamp) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(timestamp).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public getModel(modelId) {
    return readModel(this.$store)(modelId);
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public getModelName(modelId) {
    const model = this.getModel(modelId);
    return model!.name;
  }

  public isCurrentCheckpoint(modelId, checkpointName) {
    const model = this.getModel(modelId);
    const labelContainer = model!.label_containers[0];
    if (labelContainer!.checkpoint) {
      const checkpoint = labelContainer!.checkpoint;
      if (checkpoint === checkpointName) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // ==================

  public async mounted() {
    this.setupType = this.$route.query.setup_type;
    this.redirect = this.$route.query.redirect;
    console.log(typeof parseInt(this.$router.currentRoute.params.modelid, 10));
    await this.chooseDataset();
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });
    return rows;
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get token() {
    return readToken(this.$store);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }
}
